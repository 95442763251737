<template>
    <b-modal v-model="modals.discounts"
        :title="newDiscount.id ? $t('messages.updateDiscount') : $t('messages.newDiscount') "
        content-class="card form-rounded-inputs card-ecart" centered header-class="card-header"
        @hidden="fnResetProductDiscount()" cancel-variant="danger" size="md" hide-header-close>

        <v-observer tag='form' ref="formCreateDiscountVariants" class=" animated fadeIn"
            @submit.prevent="fnValidateDiscount()" v-show="!customDiscount">
            <p class="text-center my-3">
                <strong v-text="$t('components.productDiscountModal.quickDiscount')">
                </strong>
            </p>
            <p class=" mb-3 ">
                <strong v-text="$t('tables.price') + ': ' "></strong>
                <span class="text-line-through text-muted"
                    v-text=" fnFormatPrice(productInformation.price, productInformation.currency) "
                    v-show="quickNew.discount"></span>
                <span v-show="quickNew.discount"> - </span>
                <strong class="text-success"
                    v-text=" fnFormatPrice( ( productInformation.price - ( quickNew.discount * productInformation.price / 100  ))  , productInformation.currency ) "></strong>
                <br>
                <small class="text-muted" v-text="$t('components.productDiscountModal.variantsMessage') ">
                </small>
            </p>
            <div class="row mb-4">
                <div class="col-6">
                    <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.percentage') ">
                        <div class="form-group mb-0">
                            <label for="" v-text="$t('general.form.percentage') "></label>
                            <div class="form-check" v-for=" (percent,index) in percentages" :key="index"
                                :class=" { 'is-invalid':errors.length > 0 } ">
                                <input class="form-check-input" type="radio" :id="'percent'+ index"
                                    v-model="quickNew.discount" :value="percent.value" checked>
                                <label class="form-check-label" :for="'percent'+ index" v-text="percent.label">
                                </label>
                            </div>
                            <div class=" invalid-feedback " v-if="errors.length > 0">
                                {{ errors[0] }}
                            </div>
                        </div>

                    </v-validation>
                </div>
                <div class="col-6">
                    <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.dateLimit') ">
                        <div class="form-group mb-0">
                            <label for="" v-text="$t('general.form.dateLimit')"> </label>
                            <div class="form-check" v-for=" (date,index) in dates" :key="index"
                                :class=" { 'is-invalid':errors.length > 0 } ">
                                <input class="form-check-input" type="radio" :id="'date'+ index" v-model="quickNew.date"
                                    :value="date.value" checked>
                                <label class="form-check-label" :for="'date'+ index" v-text="date.label"> </label>
                            </div>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                                {{ errors[0] }}
                            </div>
                        </div>
                    </v-validation>
                </div>
            </div>
            <p class="text-right m-0 ">
                <button type="button" class="btn btn-link btn-sm p-0" @click=" customDiscount = true"
                    v-text="$t('general.button.customDiscount')"></button>
            </p>
            <input type="submit" hidden>
        </v-observer>

        <v-observer tag="form" class="form-rounded-inputs animated fadeIn" @submit.prevent="fnValidateDiscount()"
            v-slot="{ validate }" ref="discountForm" v-show="customDiscount">
            <p class="text-center my-4">
                <strong v-text="$t('components.productDiscountModal.customDiscount')"></strong>
            </p>
            <div class="row mb-2">
                <div class="col-12 col-sm-12">
                    <div class="row mb-2">
                        <div class="col">
                            <span
                                v-text=" newDiscount.is_amount ? $t('general.form.amount') : ($t('general.form.percentage') + ` (${$t('general.form.minimum')} 5%)`) ">
                            </span>
                        </div>
                        <div class="col-auto ">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="isAmount"
                                    v-model="newDiscount.is_amount" @change=" validate()  ">
                                <label class="form-check-label" for="isAmount" v-text="$t('general.form.isAmount')">
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <v-validation rules="required" v-slot="{ errors }"
                            :name=" newDiscount.is_amount ? $t('general.form.amount') : $t('general.form.percentage') ">
                            <div class="input-group">
                                <input type="number" min="5"
                                    :max=" newDiscount.is_amount ? (productInformation.price * .9 ) : 90 "
                                    class="form-control" v-model="newDiscount.percent"
                                    :class=" { 'is-invalid':errors.length > 0 } ">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa"
                                            :class=" !newDiscount.is_amount ? 'fa-percentage':'fa-dollar-sign' "></i>
                                    </span>
                                </div>
                                <div class="invalid-feedback" v-if="errors.length > 0 ">
                                    {{ errors[0] }}
                                </div>
                            </div>
                        </v-validation>
                    </div>
                    <div
                        v-if=" (newDiscount.percent >= 5 && newDiscount.percent < 100) || (newDiscount.is_amount && newDiscount.percent <= (productInformation.price * .9 ) )  ">
                        <p>
                            <strong v-text="$t('tables.price') + ': ' "></strong>
                            <span class="text-line-through text-muted"
                                v-text=" fnFormatPrice(productInformation.price, productInformation.currency) "
                                v-show="newDiscount.percent"></span>
                            <span v-show="newDiscount.percent"> - </span>
                            <strong class="text-success" v-if="!newDiscount.is_amount"
                                v-text=" fnFormatPrice( ( productInformation.price - ( newDiscount.percent * productInformation.price / 100  ))  , productInformation.currency ) "></strong>
                            <strong class="text-success" v-else
                                v-text=" fnFormatPrice( ( productInformation.price - newDiscount.percent) , productInformation.currency ) "></strong>

                        </p>
                    </div>
                    <hr>
                </div>
                <div class="col-12 col-sm-6 ">
                    <div class="form-group">
                        <label for="" v-text=" $t('general.form.initialDate') "></label>
                        <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.initialDate') ">
                            <v-date-picker output-format='x' :formatted="CONST.DATE_FORMATS.DD_MM_YYYY" lang="en"
                                @input="fnDateNotBeforeTo() ; fnStringDates()" right v-model="newDiscount.from"
                                :min-date=" CONST.TODAY " no-header only-date no-button-now dark no-button auto-close>
                                <input type="text" class="form-control" :class=" { 'is-invalid':errors.length > 0 } "
                                    v-model=" stringDates.start ">
                            </v-date-picker>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                                {{ errors[0] }}
                            </div>
                        </v-validation>
                    </div>
                </div>
                <div class="col-12 col-sm-6 ">
                    <div class="form-group">
                        <label for="" v-text=" $t('general.form.finishDate') "></label>
                        <v-validation :rules=" !newDiscount.unlimit ? 'validDate' : ''" v-slot="{ errors }"
                            :name=" $t('general.form.finishDate')">
                            <v-date-picker output-format='x' :formatted="CONST.DATE_FORMATS.DD_MM_YYYY" right
                                :class=" { 'is-invalid':errors.length > 0 } " @input=" fnStringDates() "
                                v-model="newDiscount.to" :min-date="notBefore" no-header only-date no-button-now dark
                                no-button auto-close>
                                <input type="text" class="form-control" v-model=" stringDates.end "
                                    :disabled=" newDiscount.unlimit " :class=" { 'is-invalid':errors.length > 0 } ">
                            </v-date-picker>
                            <div class="invalid-feedback" v-if="errors.length > 0">
                                {{ errors[0] }}
                            </div>
                        </v-validation>
                    </div>
                </div>
                <div class="col-auto ml-auto">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="isUnlimited" v-model="newDiscount.unlimit">
                        <label class="form-check-label" for="isUnlimited" v-text="$t('general.form.unlimited')">
                        </label>
                    </div>
                </div>
            </div>
            <v-validation :rules="{'required': productInformation.variants.length > 0}" v-slot="{ errors }"
                :name=" $t('tables.selectVariants') " v-show="productInformation.variants.length > 0">
                <div class="">
                    <table class=" table table-striped table-hover ">
                        <thead>
                            <tr>
                                <th> <input type="checkbox" id="selectAllVAriants" :checked="selectAllVariants"
                                        @click=" fnSelectAllVariants() ">
                                </th>
                                <th class="col">
                                    <label :for=" 'selectAllVAriants' " class=" m-0"
                                        v-text=" $t('tables.selectVariants') ">
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="" v-for=" (variant,index) in productInformation.variants " :key=" index ">
                                <td>
                                    <input type="checkbox" v-model="newDiscount.variant_ids" :value="variant.id"
                                        :id="variant.id">
                                </td>
                                <td>
                                    <label :for=" variant.id " class="m-0 w-100">
                                        <p class="m-0">
                                            <span v-text=" $t('tables.product') + ': ' "></span>
                                            <strong v-text=" variant.name "></strong>
                                        </p>
                                        <p class="m-0 d-none"> <span v-text=" $t('tables.discount') + ': ' "></span>
                                            <strong
                                                v-text=" (variant.discount_percent ? variant.discount_percent : 0) + '%' "></strong>
                                        </p>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bg-danger px-2" v-if="errors.length > 0">
                    {{ errors[0] }}
                </div>
            </v-validation>
            <input type="submit" hidden>
        </v-observer>

        <template v-slot:modal-footer>
            <div class="d-flex w-100">
                <b-button variant="primary" class=" mr-2" @click=" customDiscount = false "
                    v-text=" $t('general.button.back') " v-if=" customDiscount ">
                </b-button>
                <div class="ml-auto">
                    <b-button variant="none" class="" @click="fnResetProductDiscount() "
                        v-text=" $t('general.button.close') ">
                    </b-button>
                    <b-button variant="success" class="" @click=" fnValidateDiscount() " :disabled="sendingData">
                        <span v-text="newDiscount.id ? $t('general.button.update') : $t('general.button.create') "
                            v-if=" !sendingData "></span>
                        <i class="fa fa-spinner fa-spin " v-else></i>
                    </b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import {
        mapMutations,
        mapState
    } from 'vuex';

    export default {
        name: "ProductDiscountModal",
        components: {
            DatePicker,
        },
        computed: {
            ...mapState('EcartProduct', ['productInformation', 'modals', 'newDiscount']),
            selectAllVariants() {
                return this.newDiscount.variant_ids.length == this.productInformation.variants.length;
            },
        },
        watch: {
            newDiscount() {
                if (this.newDiscount.id) {
                    this.customDiscount = true;
                    this.fnStringDates();
                }
            }
        },
        data() {
            return ({
                percentages: [{
                        value: 10,
                        label: '10%'
                    },
                    {
                        value: 25,
                        label: '25%'
                    },
                    {
                        value: 50,
                        label: '50%'
                    },
                ],
                dates: [{
                        value: 7,
                        label: this.$tc('messages.days', 1, {
                            days: 7
                        }),
                    },
                    {
                        value: 30,
                        label: this.$tc('messages.days', 1, {
                            days: 30
                        }),
                    },
                    {
                        value: 120,
                        label: this.$t('general.form.unlimited'),
                    },
                ],
                quickNew: {
                    discount: null,
                    date: null,
                },
                notBefore: null,
                sendingData: false,
                stringDates: {
                    start: null,
                    end: null,
                },
                customDiscount: false,
                datesErrors: {
                    from: false,
                    to: false,
                }
            })
        },
        methods: {
            ...mapMutations('EcartProduct', ['changeModal', 'addDiscount', 'updateDiscount']),
            async fnValidateDiscount() {
                if (this.customDiscount) {
                    this.fnApiAddDiscount();
                } else {
                    if (await this.$refs['formCreateDiscountVariants'].validate()) {
                        this.newDiscount.percent = this.quickNew.discount;
                        this.newDiscount.is_amount = false;
                        this.newDiscount.from = new Date().setHours(0, 0, 0, 0);
                        this.newDiscount.to = this.$moment(this.newDiscount.from).add(this.quickNew.date, 'days')
                            .format('x');
                        if (this.quickNew.date < 120) {
                            this.newDiscount.unlimit = false;
                        } else {
                            this.newDiscount.unlimit = true;
                        }
                        this.newDiscount.from = this.$moment(this.newDiscount.from).format('x');
                        if (this.productInformation.variants && this.productInformation.variants.length > 0) {
                            this.newDiscount.variant_ids = this.productInformation.variants.map(variant => variant
                                .id);
                        } else {
                            this.newDiscount.variant_ids = []
                        }
                        setTimeout(() => {
                            this.fnApiAddDiscount();
                        }, 200);
                    }

                }
            },
            async fnApiAddDiscount() {
                if (await this.$refs['discountForm'].validate()) {
                    this.sendingData = true;
                    let {
                        percent,
                        from,
                        to,
                        variant_ids,
                        unlimit,
                        is_amount,
                        active,
                    } = this.newDiscount;
                    let tempnewDiscount = {
                        percent,
                        from,
                        unlimit,
                        is_amount,
                        active,
                    };
                    if (variant_ids.length > 0) {
                        tempnewDiscount.variant_ids = variant_ids;
                    };
                    if (tempnewDiscount.unlimit) {
                        tempnewDiscount.to = tempnewDiscount.from;
                    } else {
                        tempnewDiscount.to = to;
                    }
                    if (this.newDiscount.id) {
                        await axios.put(`products/${this.productInformation.id}/discounts/${this.newDiscount.id}`,
                            tempnewDiscount).then((response) => {
                            this.$toasted.global.infoMessage(this.$t(
                                'toasted.info.productDiscountUpdated'));
                            this.updateDiscount(response.data);
                            this.fnResetProductDiscount();
                        }).catch(error => {});
                    } else {
                        await axios.post(`products/${this.productInformation.id}/discounts`, tempnewDiscount).then((
                            response) => {
                            this.$toasted.global.infoMessage(this.$t(
                                'toasted.info.productDiscountUpdated'));
                            this.addDiscount(response.data);
                            this.fnResetProductDiscount();
                        }).catch(error => {});
                    }
                    this.sendingData = false;
                } else {
                    // console.log(this.$refs['discountForm'].errors);
                }
            },

            fnResetProductDiscount() {
                this.customDiscount = false;
                this.newDiscount.variant_ids = [];
                this.newDiscount.id = null;
                this.newDiscount.is_amount = false;
                this.newDiscount.percent = 20;
                this.newDiscount.from = new Date().setHours(0, 0, 0, 0);
                this.newDiscount.to = this.$moment(this.newDiscount.from).add(30, 'days').format('x');
                this.newDiscount.from = this.$moment(this.newDiscount.from).format('x');
                this.quickNew.date = null;
                this.quickNew.discount = null;
                this.fnDateNotBeforeTo();
                this.changeModal({
                    discounts: false
                });
            },
            fnDateNotBeforeTo() {
                this.notBefore = this.$moment(this.newDiscount.from, 'x').add(1, 'days').format();
                this.fnDateResetFinishDate();
                this.fnStringDates();
            },
            fnDateResetFinishDate() {
                this.newDiscount.to = this.$moment(this.newDiscount.from, 'x').add(30, 'days').format('x');
            },
            fnStringDates() {
                this.stringDates.start = this.$moment(this.newDiscount.from, 'x').format(this.CONST.DATE_FORMATS
                    .DD_MM_YYYY);
                this.stringDates.end = this.$moment(this.newDiscount.to, 'x ').format(this.CONST.DATE_FORMATS
                    .DD_MM_YYYY);
            },

            fnSelectAllVariants() {
                if (this.newDiscount.variant_ids.length < this.productInformation.variants.length) {
                    this.newDiscount.variant_ids = [];
                    for (let index in this.productInformation.variants) {
                        this.newDiscount.variant_ids.push(this.productInformation.variants[index].id);
                    }
                } else {
                    this.newDiscount.variant_ids = [];
                }
            },
        },
        async mounted() {
            this.fnResetProductDiscount();
        }
    }
</script>