<template>
    <div class="card">
        <div class="card-body">

            <div class="row align-items-center mb-2">
                <div class="col">
                    <h6 class="m-0" v-text="$t('general.discounts')"></h6>
                </div>
                <div class="col-auto">
                    <button class="btn btn-success circle-25 " @click=" changeModal({ discounts:true}) ">
                        <i class="fa fa-plus-circle"></i>
                    </button>
                </div>
            </div>

            <div class="card" v-if="productInformation.discounts == 0">
                <div class="card-body p-2 p-md-4 bg-light ">
                    <p class="text-muted text-center">
                        <small class="f-w-500" v-text="$t('dashboard.product.discountRecomendation')"></small>
                    </p>
                </div>
            </div>

            <table class="table table-striped table-bordered animated fadeIn ">
                <tbody>
                    <tr v-for=" (discount,index) in productInformation.discounts" :key="discount.id ">
                        <td>
                            <div class="row align-items-center justify-content-between ">
                                <div class="col">
                                    <div class="row">
                                        <div class="col-auto">
                                            <button class="btn  circle-20 btn-sm" @click="fnApiDefaultDiscount(index)" :disabled="discount.disabled"
                                                :class="discount.active ? 'btn-success' : 'btn-outline-secondary' ">
                                                <i class="fa fa-spinner fa-spin "
                                                    v-if=" (loading.defaultDiscount != null) && loading.defaultDiscount == index"></i>
                                                <i class="fa fa-star fa-sm " v-else></i>
                                            </button>
                                        </div>
                                        <div class="col pl-0">
                                            <p class="text-capitalize">
                                                <span
                                                    v-text=" fnFormatPrice( discount.percent , productInformation.currency )  "
                                                    v-if=" discount.is_amount"></span>
                                                <span v-text=" discount.percent + '%' " v-else></span>
                                                <i class=" fa fa-exclamation-circle fa-sm text-warning ml-2 "
                                                    v-if="discount.disabled"
                                                    v-b-popover.hover.bottom="$t('messages.discountExpired')"></i>
                                                <i class=" fa fa-exclamation-circle fa-sm text-warning ml-2 "
                                                    v-if=" fnCheckVariants(discount.variant_ids) "
                                                    v-b-popover.hover.bottom="$t('messages.discountVariantsDoesnotExist')"></i>
                                                <i class=" fa fa-question-circle fa-sm text-info ml-2 "
                                                    v-if="discount.from_batch"
                                                    v-b-popover.hover.bottom="$t('messages.bulkDisocunt')"></i>
                                                
                                            </p>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-auto d-flex">
                                    <button class="btn btn-light btn-sm circle-20" @click="editDiscount(index)"
                                        :disabled="discount.from_batch">
                                        <i class="fa fa-pencil-alt fa-sm"
                                            :class=" discount.from_batch ? '' : 'text-info' "></i>
                                    </button>
                                    <button class="btn btn-light btn-sm circle-20  ml-2"
                                        @click="fnApiDeleteDiscount(index)" :disabled="discount.from_batch">
                                        <i class="fa fa-spinner fa-spin "
                                            v-if=" (loading.deleteDiscount != null) && loading.deleteDiscount == index"></i>
                                        <i class="fa fa-trash-alt fa-sm "
                                            :class=" discount.from_batch ? '' : 'text-danger' " v-else></i>
                                    </button>
                                </div>
                            </div>
                            <!-- <pre> {{ discount }} </pre> -->
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <pre> {{productInformation.discounts }} </pre> -->
            <ProductDiscount />
        </div>
    </div>
</template>

<script>
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex'
    import ProductDiscount from "./product-discount-modal.vue";
    export default {
        components: {
            ProductDiscount,
        },
        data() {
            return {}
        },
        computed: {
            ...mapState('EcartProduct', ['productInformation', 'loading']),
        },
        methods: {
            ...mapMutations('EcartProduct', ['changeModal', 'editDiscount']),
            ...mapActions('EcartProduct', ['fnApiDeleteDiscount', 'fnApiDefaultDiscount']),
            fnCheckVariants(varaintsList) {
                if (varaintsList) {
                    let variants = false;
                    varaintsList.map(varId => {
                        if (this.productInformation.variants.find(variant => variant.id == varId) == null) {
                            variants = true;
                        }
                    });
                    return variants
                }
                return false;
            }
        }
    }
</script>

<style>

</style>